import React, { useEffect, useState } from "react"
import guardianCounselApi from "../../apis/guardianCounselApi"

interface IInvitation {
  id: number
  firstName: string
  lastName: string
  email: string
  oneDriveUrl: string
}

const InvitationListItem = ({ invitation, onRemove }: { invitation: IInvitation; onRemove: any }) => {
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid white",
          padding: "20px",
        }}
      >
        <p style={{ flexGrow: 1 }}>
          Name: {invitation.lastName}, {invitation.firstName} ({invitation.email})
        </p>
        <a className="btn btn-info m-1" target="blank" href={invitation.oneDriveUrl}>
          View on One Drive
        </a>
        {!showConfirm && (
          <button onClick={() => setShowConfirm(true)} className="btn btn-warning m-1">
            Remove Invitation
          </button>
        )}
        {showConfirm && (
          <div>
            <span style={{ padding: "0 50px" }}>Are you sure?</span>
            <button onClick={onRemove} className="btn btn-danger m-1">
              Yes, Remove
            </button>
            <button onClick={() => setShowConfirm(false)} className="btn btn-success m-1">
              No, Keep
            </button>
          </div>
        )}
      </div>
    </>
  )
}

const OneDriveInvitationList = () => {
  const [invitations, setInvitations] = useState<IInvitation[]>([])

  const onRemoveInvitation = async (id: number) => {
    setInvitations((oldInvitations) => oldInvitations.filter((ov) => ov.id !== id))

    await guardianCounselApi.delete("/invitation", { data: { id } })
  }

  useEffect(() => {
    const loadInvitations = async () => {
      var response = await guardianCounselApi.get("/invitation/all")
      setInvitations(response.data)
    }
    loadInvitations()
  }, [])

  return (
    <>
      {invitations.map((i) => (
        <InvitationListItem key={i.id} onRemove={() => onRemoveInvitation(i.id)} invitation={i} />
      ))}
      {invitations.length === 0 && <p>There are no active invitations yet. Use the button above to create one.</p>}
    </>
  )
}

export default OneDriveInvitationList
