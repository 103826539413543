import React from "react"

import styles from "./dashboard.module.scss"

import Layout from "../components/layout/layout"
import OneDriveInvitationList from "../components/one-drive/oneDriveInvitationList"
import { Link } from "gatsby"

const Dashboard = () => {
  return (
    <>
      <Layout>
        <br />
        <br />

        <h1 style={{ textAlign: "center" }}>Dashboard - OneDrive Invitations</h1>
        <br />

        <div style={{ textAlign: "center", marginBottom: "25px" }}>
          <Link className="btn btn-success" to="/invitations/create">
            Create New Invitation
          </Link>
        </div>

        <div style={{ flexGrow: 1, border: "1px solid white" }}>
          <OneDriveInvitationList />
        </div>
      </Layout>
    </>
  )
}

export default Dashboard
